
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'
import Tag from '@/01-atoms/Tag.vue'
import Button from '@/01-atoms/Button.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import InputText from '@/01-atoms/Input-text.vue'
import Modal from '@/02-molecules/Modal.vue'
import TitleDecoration from '@/02-molecules/Title-decoration.vue'
import BackButton from '@/02-molecules/Back-button.vue'
import DocumentsDocumentPaymentVirement from '@/04-pages/04-Documents/Documents-document-payment--virement.vue'
import DocumentsDocumentPaymentCreditCard from '@/04-pages/04-Documents/Documents-document-payment--credit-card.vue'
import DocumentsDocumentPaymentDigicash from '@/04-pages/04-Documents/Documents-document-payment--digicash.vue'
import DocumentsDocumentPaymentDomiciliation from '@/04-pages/04-Documents/Documents-document-payment--domiciliation.vue'
import { getDocument } from '@/api/documents/documents-api'
import { Certificate, Contract, Domiciliation, Invoice } from '@/services/documents/DocumentService'
import { inject } from 'vue'
import { ErrorHandlerKey, PackageServiceKey } from '@/services/serviceTypes'
import { useI18n } from 'vue-i18n'

@Options({
  name: 'Document-payment',
  components: {
    Icon,
    Tag,
    Button,
    InputLabel,
    InputText,
    Modal,
    TitleDecoration,
    BackButton,
    DocumentsDocumentPaymentVirement,
    DocumentsDocumentPaymentCreditCard,
    DocumentsDocumentPaymentDigicash,
    DocumentsDocumentPaymentDomiciliation
  }
})
export default class DocumentsDocument extends Vue {
  selectedTab = 'virement'

  isLoading = true

  getFrequency (frequency: string) {
    const { t } = useI18n()
    if (frequency !== 'undefined') {
      return t(`package.${frequency}`)
    }
  }

  beforeMount () {
    this.loadDocument()
    this.loadCurrentPackage()
  }

  packageService = inject(PackageServiceKey)
  loadCurrentPackage () {
    this.packageService?.loadCurrentPackage()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  document = {
    title: 'Placeholder name'
  } as Document | Domiciliation | Contract | Certificate | Invoice

  errorHandlerService = inject(ErrorHandlerKey)
  loadDocument () {
    const id = this.$route.params.id.toString()
    getDocument(id)
      .then((response) => {
        this.document = response.data
        this.isLoading = false
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  selectTab (tab: string) {
    this.selectedTab = tab
  }
}
