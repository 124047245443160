<template>
  <BackButton :to="{name: 'Documents'}" />
  <div :class="[isLoading && 'is-loading']">
    <div class="documents-document__header d-f mb-l">
      <div class="d-f placeholder-animation">
        <Icon class="mr-s" icon="file-text" background="blue-dark"></Icon>
        <h1 class="display-1">{{ document.title }}</h1>
      </div>
      <MoreMenu :menu="menu" @menu-action="menuAction" class="documents-document__more-menu" v-if="document.files" />
    </div>
    <div class="mb-l d-f placeholder-animation">
      <span class="display-3 mr-m">{{ $t('documents.document.status') }}:</span>
      <Tag v-if="document.status" :variant="document.status" />
    </div>
    <div class="documents-document__grid placeholder-animation">
      <div>
        <table class="documents-document__table fs-16">
          <tr>
            <th v-if="document.submittedAt">{{ $t('documents.document.submission-date') }}</th>
            <th v-if="document.paymentDueDate">{{ $t('documents.document.payment-due') }}</th>
          </tr>
          <tr>
            <td v-if="document.submittedAt">{{ formatDate(document.submittedAt) }}</td>
            <td v-if="document.paymentDueDate">{{ formatDate(document.paymentDueDate) }}</td>
          </tr>
          <tr v-if="document.membershipStartDate">
            <th>{{ $t('documents.document.membership-period') }}</th>
          </tr>
          <tr v-if="document.membershipStartDate">
            <td>{{ formatDate(document.membershipStartDate) }} - {{ formatDate(document.membershipEndDate) }}</td>
          </tr>
        </table>
        <div v-if="document.membershipStartDate">
          <p class="fs-12 tt-uppercase">{{ $t('documents.document.package') }}</p>
          <div class="mb-xl">
            <div class="d-f mb-s">
              <PackageTag :package="packageService.currentPackage" />
            </div>
            <span v-for="item in packageService.currentPackage.packageOptions" v-bind:key="item.id">+ {{ item.name }}</span>
          </div>
        </div>
        <table class="documents-document__table fs-16" v-if="document.bankAccount">
          <tr>
            <th>{{ $t('documents.document.payment-method') }}</th>
            <th></th>
          </tr>
          <tr>
            <td>{{ getPaymentMethod(document.paymentMethod) }}</td>
            <td>
              <span>{{ document.bankAccount.name }}</span>
              <br>
              <span>{{ document.bankAccount.iban }}</span>
            </td>
          </tr>
        </table>
        <div style="display: none">
          {{ $t('global.transfert') }}
          {{ $t('global.digicash') }}
          {{ $t('global.bank_domiciliation') }}
          {{ $t('global.paid_by_employer') }}
        </div>
        <Separator />
        <table class="documents-document__summary fs-16" v-if="document.amount">
          <tr>
            <th style="padding-bottom: 40px;">{{ $t('documents.document.total') }}:</th>
            <td class="fs-24">{{ convertAmountToEuro(document.amount) }}</td>
          </tr>
        </table>
        <div class="ta-center">
          <div class="mb-m">
            <Button v-if="document.documentType === 'invoice' && document.status === 'pending'"
                    :to="{name: 'Document payment'}">
              {{ $t('documents.document.pay-now-button') }}
            </Button>
            <Button v-if="document.documentType === 'certificate' && document.status === 'to_be_renewed'"
                    :to="{name: 'Document upload'}">
              {{ $t('documents.document.upload-new-certificate-button') }}
            </Button>
            <Button v-if="document.documentType === 'domiciliation' && document.status === 'to_be_renewed'"
                    :to="{name: 'Document upload'}">
              {{ $t('documents.document.upload-new-domiciliation') }}
            </Button>
          </div>
          <Button background="transparent" >{{ $t('documents.document.help-contact-us') }}</Button>
        </div>
      </div>
      <div class="documents-document__upload-grid mb-l">
        <DocumentUpload
            :item="item"
            v-bind:key="item.title"
            v-for="(item, index) in documentUploads"
            @show-uploads-lightbox="showUploadsLightbox(index)"
        />
        <vue-easy-lightbox
            escDisabled
            moveDisabled
            :imgs="lightboxImages"
            ref="uploads-lightbox"
            @hide="hideLightbox"
            :visible="lightboxIsVisible"
            :index="lightboxToOpenIndex"
        ></vue-easy-lightbox>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import Separator from '@/02-molecules/Separator.vue'
import Icon from '@/01-atoms/Icon.vue'
import HelpText from '@/02-molecules/Help-text.vue'
import Tag from '@/01-atoms/Tag.vue'
import DocumentUpload from '@/02-molecules/Document-upload.vue'
import MoreMenu from '@/02-molecules/More-menu.vue'
import Button from '@/01-atoms/Button.vue'
import BackButton from '@/02-molecules/Back-button.vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { getDocument } from '@/api/documents/documents-api'
import { ErrorHandlerKey, PackageServiceKey } from '@/services/serviceTypes'
import { inject } from 'vue'
import { Certificate, Contract, Domiciliation, Invoice, Document } from '@/services/documents/DocumentService'
import { useI18n } from 'vue-i18n'
import PackageTag from '@/02-molecules/Package-tag.vue'
import { Image } from '@/services/inquiries/InquiryService'
import { getFileDownload } from '@/api/uploads/files-api'
import sanitizeDownloadTitle from '@/helpers/sanitize-download-title'

@Options({
  name: 'Document',
  components: {
    PackageTag,
    IconButton,
    Separator,
    Icon,
    HelpText,
    Tag,
    DocumentUpload,
    MoreMenu,
    Button,
    BackButton
  }
})
export default class DocumentsDocument extends Vue {
  documentUploads: Array<Image> = []
  lightboxImages: Array<string> = []
  lightboxIsVisible = false
  lightboxToOpenIndex = 0

  isLoading = true

  getPaymentMethod (paymentMethod: string) {
    const { t } = useI18n()
    if (paymentMethod !== 'undefined') {
      return t(`global.${paymentMethod}`)
    }
  }

  beforeMount () {
    this.loadDocument()
    this.loadCurrentPackage()
  }

  menu = [
    {
      label: 'document.menu.download-document',
      icon: 'download',
      action: 'download'
    }
  ]

  menuAction (action: string) {
    switch (action) {
      case 'download': {
        this.downloadDocuments()
        break
      }
    }
  }

  downloadDocuments () {
    this.document.files.forEach((item, index) => {
      const hiddenElement = document.createElement('a')
      const downloadName = `${sanitizeDownloadTitle(this.document['title'])}-${index + 1}`
      const downloadUrl = this.lightboxImages[index]
      hiddenElement.setAttribute('download', downloadName)
      hiddenElement.setAttribute('href', downloadUrl)
      hiddenElement.click()
    })
  }

  packageService = inject(PackageServiceKey)
  loadCurrentPackage () {
    this.packageService?.loadCurrentPackage()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  document = {
    title: 'Placeholder name'
  } as Document | Domiciliation | Contract | Certificate | Invoice

  errorHandlerService = inject(ErrorHandlerKey)
  loadDocument () {
    const id = this.$route.params.id.toString()
    getDocument(id)
      .then((response) => {
        this.document = response.data
        this.isLoading = false
        this.loadImages(this.document)
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  loadImages (document: Contract | Invoice | Certificate | Domiciliation | Document) {
    document.files.forEach((file) => {
      getFileDownload(file.url)
        .then((response) => {
          const image = {
            'fileName': response.data.fileName,
            'imageContent': `data:${response.data.mimeType};base64, ${response.data.content}`
          }
          this.documentUploads.push(image)
          this.lightboxImages.push(image.imageContent)
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
    })
  }

  showUploadsLightbox (indexToOpen: number) {
    this.lightboxToOpenIndex = indexToOpen
    disableBodyScroll('uploads-lightbox')
    this.lightboxIsVisible = true
  }

  hideLightbox () {
    enableBodyScroll('uploads-lightbox')
    this.lightboxIsVisible = false
  }
}
</script>
<style lang="scss" scoped>
.documents-document__table,
.documents-document__summary {
  color: $blue-dark;
  text-align: left;
  width: 100%;

  th {
    @include brandon-grotesque;
    font-size: 12px;
    text-transform: uppercase;
    padding-top: 0;
  }

  td {
    @include brandon-grotesque-bold;
    padding: 0 0 40px;
    vertical-align: top;
  }
}

.documents-document__summary {
  td {
    text-align: right;
  }
}

.documents-document__summary__actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.documents-document__grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: $xlarge;
  align-items: flex-start;

  @include breakpoint(xmedium down) {
    display: flex;
    flex-direction: column-reverse;
    align-items: unset;
  }
}

.documents-document__upload-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, min(calc(50% - (#{$medium} / 2)), 260px));
  grid-gap: $medium;
}

.documents-document__header {
  position: relative;
}

.documents-document__more-menu {
  position: absolute;
  top: calc(50% - 23px);
  right: 0;
}
</style>
