<template>
  <div>
    <Button @click="openModal('digicash')" >{{ $t('documents.document.payment.digicash.pay-button') }}</Button>
    <Modal modalId="digicash">
      <template #title>{{ $t('documents.document.payment.digicash') }}</template>
      <div class="ta-center">
        <p class="grey-dark mb-l">{{ $t('documents.document.payment.digicash.payment-instruction') }}</p>
        <QrCode value="123" />
      </div>
    </Modal>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import Modal from '@/02-molecules/Modal.vue'
import QrCode from '@/02-molecules/Qr-code.vue'

@Options({
  name: 'Document-payment--digicash',
  components: {
    Button,
    Modal,
    QrCode
  }
})
export default class DocumentsDocumentPaymentDigicash extends Vue {

}
</script>
<style lang="scss" scoped></style>
