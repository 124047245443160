
import { Options, Vue } from 'vue-class-component'
import Tabs, { Tab } from '@/02-molecules/Tabs.vue'
import HeaderSearch from '@/03-organisms/Header-search.vue'
import { inject } from 'vue'
import { DocumentServiceKey, ErrorHandlerKey } from '@/services/serviceTypes'
import { useI18n } from 'vue-i18n'
import getTimeAgo from '@/helpers/get-time-ago'
import { DocumentBase } from '@/services/documents/DocumentService'
import TableRowDocument from '@/02-molecules/Table-row-document.vue'
import Table from '@/02-molecules/Table.vue'
import TableHeader from '@/02-molecules/Table-header.vue'

@Options({
  name: 'Documents',
  components: {
    TableHeader,
    Table,
    TableRowDocument,
    HeaderSearch,
    Tabs
  }
})
export default class Documents extends Vue {
  tabsItems = {
    invoices: {
      title: 'CMCM Invoices',
      id: 'invoices',
      currentPage: 1,
      isLoading: true
    } as Tab,
    certificates: {
      title: 'Certificates',
      id: 'certificates',
      currentPage: 1,
      isLoading: true
    } as Tab,
    other: {
      title: 'Other',
      id: 'other',
      currentPage: 1,
      isLoading: true
    } as Tab
  }

  tableHeaders = [
    {
      title: 'table.date',
      responsive: true
    },
    {
      title: 'table.status',
      responsive: false
    },
    {
      title: 'table.action',
      responsive: false
    }
  ]

  activeTabId = this.tabsItems[Object.keys(this.tabsItems)[0]].id
  itemPerPage = 20

  documentsService = inject(DocumentServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  sortedDocuments (type: string) {
    return this.sortDocuments(this.documentsService?.documents[type])
  }

  sortDocuments (documents: any) {
    const newArray = [[], [], [], [], []]
    documents.forEach((document: DocumentBase) => {
      const index = getTimeAgo(document.submittedAt)!.index
      newArray[index].push(document)
    })
    return newArray
  }

  beforeMount () {
    this.setTranslations()
    this.loadAllDocuments()
  }

  loadAllDocuments () {
    this.loadEntries('invoices', 1, this.itemPerPage)
    this.loadEntries('certificates', 1, this.itemPerPage)
    this.loadEntries('other', 1, this.itemPerPage)
  }

  setTranslations () {
    const { t } = useI18n()
    this.tabsItems['invoices'].title = t('global.invoices')
    this.tabsItems['certificates'].title = t('global.certificates')
    this.tabsItems['other'].title = t('global.other')
  }

  loadEntries (type = this.activeTabId, currentPage = 1, itemPerPage = this.itemPerPage) {
    this.documentsService?.loadDocuments(type, currentPage, itemPerPage)
        .then(() => {
          this.tabsItems[type].isLoading = false
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  isLoading () {
    for (const tabsItemsKey in this.tabsItems) {
      if (this.tabsItems[tabsItemsKey].isLoading) {
        return true
      }
    }
    return false
  }

  loadOlderEntries () {
    this.tabsItems[this.activeTabId].isLoading = true
    const currentPage = this.setCurrentPage()
    this.documentsService?.loadOlderDocuments(this.activeTabId, currentPage, this.itemPerPage)
        .then(() => {
          this.tabsItems[this.activeTabId].isLoading = false
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  setCurrentPage () {
    this.tabsItems[this.activeTabId].currentPage = this.tabsItems[this.activeTabId].currentPage + 1
    return this.tabsItems[this.activeTabId].currentPage
  }

  switchTab (id: string) {
    this.activeTabId = id
    this.loadEntries()
  }
}
