<template>
  <div>
    <Confirmation>
      <template #header>{{ $t('documents.document.upload-document-confirmation.title') }}</template>
      <template #title>{{ $t('documents.document.upload-document-confirmation.subtitle') }}</template>
      <template #content>
        <p class="mb-l">{{ $t('documents.document.upload-document-confirmation.text') }}</p>
        <Button :to="{name: 'Documents'}" >{{ $t('documents.title') }}</Button>
      </template>
    </Confirmation>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Confirmation from '@/03-organisms/Confirmation.vue'
import Button from '@/01-atoms/Button.vue'

@Options({
  name: 'Document-upload-confirmation',
  components: {
    Confirmation,
    Button
  }
})
export default class DocumentNewDocumentConfirmation extends Vue { }
</script>
<style lang="scss" scoped>

</style>
