<template>
  <div>
    <p class="grey-dark mb-l">
      {{ $t('documents.document.payment.bank-transfer.payment-instructions') }}
    </p>
    <span class="tt-uppercase fs-12 d-b mb-s">{{ $t('documents.document.iban') }}</span>
    <CopyButton class="mb-l">LU 28ZZ Z000 0000 0083 9800 2001</CopyButton>
    <span class="tt-uppercase fs-12 d-b mb-s">{{ $t('documents.document.bic') }}</span>
    <CopyButton class="mb-l">5000 0000 054</CopyButton>
    <span class="tt-uppercase fs-12 d-b mb-s">{{ $t('documents.document.total-amount') }}</span>
    <CopyButton class="mb-l" :copy="amount">{{ convertAmountToEuro(amount) }}</CopyButton>
    <span class="tt-uppercase fs-12 d-b mb-s">{{ $t('documents.document.communication') }}</span>
    <CopyButton class="mb-l">A-081100</CopyButton>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import CopyButton from '@/02-molecules/Copy-button.vue'

class Props {
  amount?: string
}

@Options({
  name: 'document-payment-virement',
  components: {
    CopyButton
  }
})
export default class DocumentsDocumentPaymentVirement extends Vue.with(Props) { }
</script>
<style lang="scss" scoped></style>
