<template>
  <div>
    <p class="grey-dark mb-l">Domiciliation text</p>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'

@Options({
  name: 'Document-payment--domiciliation',
  components: {}
})
export default class DocumentsDocumentPaymentDomiciliation extends Vue {
}
</script>
<style lang="scss" scoped>

</style>
