
import { Options, Vue } from 'vue-class-component'
import CopyButton from '@/02-molecules/Copy-button.vue'

class Props {
  amount?: string
}

@Options({
  name: 'document-payment-virement',
  components: {
    CopyButton
  }
})
export default class DocumentsDocumentPaymentVirement extends Vue.with(Props) { }
