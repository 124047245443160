
import { Options, Vue } from 'vue-class-component'
import InputLabel from '@/01-atoms/Input-label.vue'
import InputText from '@/01-atoms/Input-text.vue'
import Button from '@/01-atoms/Button.vue'

@Options({
  name: 'Document--payment-credit-card',
  components: {
    InputLabel,
    InputText,
    Button
  }
})
export default class DocumentsDocumentPaymentCreditCard extends Vue {
  inputCardName = ''
  inputCardNumber = ''

  get cardNumber () {
    if (this.inputCardNumber.length > 0) {
      return this.inputCardNumber
    } else {
      return '0000 0000 0000 0000'
    }
  }

  get cardName () {
    return this.inputCardName
  }
}
