
import { Options, Vue } from 'vue-class-component'
import Confirmation from '@/03-organisms/Confirmation.vue'
import Button from '@/01-atoms/Button.vue'

@Options({
  name: 'Document-upload-confirmation',
  components: {
    Confirmation,
    Button
  }
})
export default class DocumentNewDocumentConfirmation extends Vue { }
