<template>
  <div class="documents-document-payment-credit-card">
    <div class="documents-document-payment-credit-card__form">
      <div class="documents-document-payment-credit-card__form-column">
        <InputLabel>{{ $t('documents.document.payment.credit-card.number') }}</InputLabel>
        <InputText type="number" class="mb-l" v-model="inputCardNumber"></InputText>
      </div>
      <div class="documents-document-payment-credit-card__form-column documents-document-payment-credit-card__form-column--split mr-l">
        <InputLabel>{{ $t('documents.document.payment.credit-card.expiry-date') }}</InputLabel>
        <InputText type="number" class="mb-l" placeholder="MM/YY"></InputText>
      </div>
      <div class="documents-document-payment-credit-card__form-column documents-document-payment-credit-card__form-column--split">
        <InputLabel>{{ $t('documents.document.payment.credit-card.cvv') }}</InputLabel>
        <InputText type="number" class="mb-l" placeholder="000"></InputText>
      </div>
      <div class="documents-document-payment-credit-card__form-column">
        <InputLabel>{{ $t('documents.document.payment.credit-card.cardholder-name') }}</InputLabel>
        <InputText class="mb-m" v-model="inputCardName" style="min-height: 20px"></InputText>
      </div>
    </div>
    <div class="xm-up">
      <div class="documents-document-payment-credit-card__card">
        <span class="fw-700 fs-14 mb-s">{{ cardNumber }}</span>
        <span class="fw-700 fs-12" style="min-height: 20px">{{ cardName }}</span>
      </div>
    </div>
    <div class="documents-document-payment-credit-card__footer">
      <Button >{{ $t('documents.document.confirm-pay-button') }}</Button>
    </div>
  </div>

</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import InputLabel from '@/01-atoms/Input-label.vue'
import InputText from '@/01-atoms/Input-text.vue'
import Button from '@/01-atoms/Button.vue'

@Options({
  name: 'Document--payment-credit-card',
  components: {
    InputLabel,
    InputText,
    Button
  }
})
export default class DocumentsDocumentPaymentCreditCard extends Vue {
  inputCardName = ''
  inputCardNumber = ''

  get cardNumber () {
    if (this.inputCardNumber.length > 0) {
      return this.inputCardNumber
    } else {
      return '0000 0000 0000 0000'
    }
  }

  get cardName () {
    return this.inputCardName
  }
}
</script>
<style lang="scss" scoped>
.documents-document-payment-credit-card {
  display: flex;
  flex-wrap: wrap;
  grid-gap: $xmedium;
}

.documents-document-payment-credit-card__form {
  display: flex;
  flex-wrap: wrap;
  max-width: 350px;
}

.documents-document-payment-credit-card__form-column {
  width: 100%;

  &--split {
    width: 100px;
  }
}

.documents-document-payment-credit-card__card {
  background-color: $blue-dark-10;
  border-radius: 10px;
  box-shadow: 1px 2px 10px -2px $blue-dark-10;
  display: flex;
  height: 153px;
  flex-direction: column;
  justify-content: flex-end;
  width: 243px;
  padding: 15px;
}

.documents-document-payment-credit-card__footer {
  width: 100%;
}
</style>
