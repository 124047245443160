
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import Modal from '@/02-molecules/Modal.vue'
import QrCode from '@/02-molecules/Qr-code.vue'

@Options({
  name: 'Document-payment--digicash',
  components: {
    Button,
    Modal,
    QrCode
  }
})
export default class DocumentsDocumentPaymentDigicash extends Vue {

}
