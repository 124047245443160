<template>
  <BackButton :to="{name: 'Document'}" />
  <h1 class="display-1 mb-l">{{ $t('documents.document.upload-document') }}</h1>
  <NewInquiryUploads @add-new-inquiry-upload="addNewDocumentUpload"
                     @remove-new-inquiry-upload="removeNewDocumentUpload"
                     :newInquiryUploads="documentUploads"
                     class="mb-l" />
  <Separator></Separator>
  <div class="ta-center">
    <LoadingDots v-if="isLoading" />
    <Button v-else @click="submit($event)" type="submit">{{ $t('global.upload') }}</Button>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Button from '@/01-atoms/Button.vue'
import Separator from '@/02-molecules/Separator.vue'
import NewInquiryUploads from '@/03-organisms/New-inquiry-uploads.vue'
import BackButton from '@/02-molecules/Back-button.vue'
import { Upload } from '@/04-pages/02-Inquiries/Inquiries-new-inquiry.vue'
import { inject } from 'vue'
import { ErrorHandlerKey } from '@/services/serviceTypes'
import router from '@/router'
import { patchDocument } from '@/api/documents/documents-api'
import LoadingDots from '@/01-atoms/Loading-dots.vue'

@Options({
  name: 'Document-upload',
  components: {
    LoadingDots,
    Button,
    Separator,
    NewInquiryUploads,
    BackButton
  }
})
export default class DocumentsNewDocument extends Vue {
  documentUploads: Array<Upload> = []

  isLoading = false

  errorHandlerService = inject(ErrorHandlerKey)

  submit (ev: Event) {
    this.isLoading = true
    ev.preventDefault()
    this.createPatchInquiry()
  }

  createPatchInquiry () {
    this.patchInquiry(this.createFilesIdList())
  }

  createFilesIdList () {
    const filesIds: Array<string> = []
    this.documentUploads.forEach((document) => {
      filesIds.push(document.id)
    })
    return filesIds
  }

  patchInquiry (files: Array<string>) {
    const documentId = this.$route.params.id.toString()
    patchDocument(documentId, files)
      .then(() => {
        router.push({ name: 'Document upload confirmation' })
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  addNewDocumentUpload (upload: Upload) {
    this.documentUploads.push(upload)
  }

  removeNewDocumentUpload (id: string) {
    this.documentUploads = this.documentUploads.filter((item) => {
      return id !== item.id
    })
  }
}
</script>
