
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import Separator from '@/02-molecules/Separator.vue'
import Icon from '@/01-atoms/Icon.vue'
import HelpText from '@/02-molecules/Help-text.vue'
import Tag from '@/01-atoms/Tag.vue'
import DocumentUpload from '@/02-molecules/Document-upload.vue'
import MoreMenu from '@/02-molecules/More-menu.vue'
import Button from '@/01-atoms/Button.vue'
import BackButton from '@/02-molecules/Back-button.vue'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { getDocument } from '@/api/documents/documents-api'
import { ErrorHandlerKey, PackageServiceKey } from '@/services/serviceTypes'
import { inject } from 'vue'
import { Certificate, Contract, Domiciliation, Invoice, Document } from '@/services/documents/DocumentService'
import { useI18n } from 'vue-i18n'
import PackageTag from '@/02-molecules/Package-tag.vue'
import { Image } from '@/services/inquiries/InquiryService'
import { getFileDownload } from '@/api/uploads/files-api'
import sanitizeDownloadTitle from '@/helpers/sanitize-download-title'

@Options({
  name: 'Document',
  components: {
    PackageTag,
    IconButton,
    Separator,
    Icon,
    HelpText,
    Tag,
    DocumentUpload,
    MoreMenu,
    Button,
    BackButton
  }
})
export default class DocumentsDocument extends Vue {
  documentUploads: Array<Image> = []
  lightboxImages: Array<string> = []
  lightboxIsVisible = false
  lightboxToOpenIndex = 0

  isLoading = true

  getPaymentMethod (paymentMethod: string) {
    const { t } = useI18n()
    if (paymentMethod !== 'undefined') {
      return t(`global.${paymentMethod}`)
    }
  }

  beforeMount () {
    this.loadDocument()
    this.loadCurrentPackage()
  }

  menu = [
    {
      label: 'document.menu.download-document',
      icon: 'download',
      action: 'download'
    }
  ]

  menuAction (action: string) {
    switch (action) {
      case 'download': {
        this.downloadDocuments()
        break
      }
    }
  }

  downloadDocuments () {
    this.document.files.forEach((item, index) => {
      const hiddenElement = document.createElement('a')
      const downloadName = `${sanitizeDownloadTitle(this.document['title'])}-${index + 1}`
      const downloadUrl = this.lightboxImages[index]
      hiddenElement.setAttribute('download', downloadName)
      hiddenElement.setAttribute('href', downloadUrl)
      hiddenElement.click()
    })
  }

  packageService = inject(PackageServiceKey)
  loadCurrentPackage () {
    this.packageService?.loadCurrentPackage()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  document = {
    title: 'Placeholder name'
  } as Document | Domiciliation | Contract | Certificate | Invoice

  errorHandlerService = inject(ErrorHandlerKey)
  loadDocument () {
    const id = this.$route.params.id.toString()
    getDocument(id)
      .then((response) => {
        this.document = response.data
        this.isLoading = false
        this.loadImages(this.document)
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  loadImages (document: Contract | Invoice | Certificate | Domiciliation | Document) {
    document.files.forEach((file) => {
      getFileDownload(file.url)
        .then((response) => {
          const image = {
            'fileName': response.data.fileName,
            'imageContent': `data:${response.data.mimeType};base64, ${response.data.content}`
          }
          this.documentUploads.push(image)
          this.lightboxImages.push(image.imageContent)
        })
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
    })
  }

  showUploadsLightbox (indexToOpen: number) {
    this.lightboxToOpenIndex = indexToOpen
    disableBodyScroll('uploads-lightbox')
    this.lightboxIsVisible = true
  }

  hideLightbox () {
    enableBodyScroll('uploads-lightbox')
    this.lightboxIsVisible = false
  }
}
