<template>
  <BackButton :to="{name: 'Document'}" />
  <div :class="[isLoading && 'is-loading']">
    <div class="documents-document-payment__title mb-l placeholder-animation">
      <Icon icon="file-text" class="mr-m" background="blue-dark"></Icon>
      <h1 class="display-1">{{ document.title }}</h1>
    </div>
    <div class="mb-m documents-document-payment__status placeholder-animation">
      <span class="display-3 mr-m">{{ $t('documents.document.status') }}: </span>
      <Tag :variant="document.status" />
    </div>
    <div class="documents-document-payment__grid placeholder-animation">
      <div class="documents-document-payment__overview">
        <span class="fs-12 tt-uppercase mb-s d-f">{{ $t('documents.document.membership-period') }}</span>
        <span class="fs-16 fw-700 mb-l d-f">{{ formatDate(document.membershipStartDate) }} - {{ formatDate(document.membershipEndDate) }}</span>
        <span class="fs-12 tt-uppercase mb-s d-f">{{ $t('documents.document.payment-frequency') }}</span>
        <span class="fs-16 fw-700 mb-l d-f">{{ getFrequency(document.frequency) }}</span>
        <span class="fs-12 tt-uppercase mb-s d-f">{{ $t('documents.document.package') }}</span>
        <table class="documents-document-payment__table">
          <tr>
            <td>{{ packageService.currentPackage.name }}</td>
            <td class="fw-700" style="text-align: right;" v-if="document.frequency === 'yearly' || document.frequency === 'bi_yearly'">
              {{ convertAmountToEuro(packageService.currentPackage.feeAnnually, document.frequency) }}
            </td>
            <td class="fw-700" style="text-align: right;" v-else-if="document.frequency === 'monthly'">
              {{ convertAmountToEuro(packageService.currentPackage.feeMonthly) }}
            </td>
          </tr>
          <tr v-for="option in packageService.currentPackage.packageOptions" v-bind:key="option.id">
            <td class="fw-700">+ {{ option.name }}</td>
            <td class="fw-700" style="text-align: right;">{{ convertAmountToEuro(option.fee) }}</td>
          </tr>
          <tr>
            <th colspan="2" style="padding-bottom: 100px;"></th>
          </tr>
          <tr>
            <th colspan="2" class="documents-document-payment__table-separator"></th>
          </tr>
          <tr>
            <td class="tt-uppercase">{{ $t('documents.document.total') }}:</td>
            <td class="fw-700 fs-24" style="text-align: right;">{{ convertAmountToEuro(document.amount) }}</td>
          </tr>
        </table>
        <div style="display: none">
          {{ $t('package.yearly') }}
          {{ $t('package.bi_yearly') }}
          {{ $t('package.monthly') }}
        </div>
      </div>
      <div class="documents-document-payment__payment">
        <TitleDecoration>{{ $t('documents.document.enter-payment-details') }}</TitleDecoration>
        <div class="mb-l">
          <Button @click="selectTab('virement')" class="mr-m mb-s" :background="selectedTab !== 'virement' && 'blue-dark-10'" >
            {{ $t('documents.document.payment.bank-transfer') }}
          </Button>
          <Button @click="selectTab('credit-card')" class="mr-m mb-s" :background="selectedTab !== 'credit-card' && 'blue-dark-10'" >
            {{ $t('documents.document.payment.credit-card') }}
          </Button>
          <Button @click="selectTab('digicash')" class="mr-m mb-s" :background="selectedTab !== 'digicash' && 'blue-dark-10'" >
            {{ $t('documents.document.payment.digicash') }}
          </Button>
          <Button @click="selectTab('domiciliation')" :background="selectedTab !== 'domiciliation' && 'blue-dark-10'" >
            {{ $t('documents.document.domiciliation') }}
          </Button>
        </div>
        <DocumentsDocumentPaymentVirement v-if="selectedTab === 'virement'" :amount="document.amount" />
        <DocumentsDocumentPaymentCreditCard v-else-if="selectedTab === 'credit-card'" />
        <DocumentsDocumentPaymentDigicash v-else-if="selectedTab === 'digicash'" />
        <DocumentsDocumentPaymentDomiciliation v-else-if="selectedTab === 'domiciliation'" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Icon from '@/01-atoms/Icon.vue'
import Tag from '@/01-atoms/Tag.vue'
import Button from '@/01-atoms/Button.vue'
import InputLabel from '@/01-atoms/Input-label.vue'
import InputText from '@/01-atoms/Input-text.vue'
import Modal from '@/02-molecules/Modal.vue'
import TitleDecoration from '@/02-molecules/Title-decoration.vue'
import BackButton from '@/02-molecules/Back-button.vue'
import DocumentsDocumentPaymentVirement from '@/04-pages/04-Documents/Documents-document-payment--virement.vue'
import DocumentsDocumentPaymentCreditCard from '@/04-pages/04-Documents/Documents-document-payment--credit-card.vue'
import DocumentsDocumentPaymentDigicash from '@/04-pages/04-Documents/Documents-document-payment--digicash.vue'
import DocumentsDocumentPaymentDomiciliation from '@/04-pages/04-Documents/Documents-document-payment--domiciliation.vue'
import { getDocument } from '@/api/documents/documents-api'
import { Certificate, Contract, Domiciliation, Invoice } from '@/services/documents/DocumentService'
import { inject } from 'vue'
import { ErrorHandlerKey, PackageServiceKey } from '@/services/serviceTypes'
import { useI18n } from 'vue-i18n'

@Options({
  name: 'Document-payment',
  components: {
    Icon,
    Tag,
    Button,
    InputLabel,
    InputText,
    Modal,
    TitleDecoration,
    BackButton,
    DocumentsDocumentPaymentVirement,
    DocumentsDocumentPaymentCreditCard,
    DocumentsDocumentPaymentDigicash,
    DocumentsDocumentPaymentDomiciliation
  }
})
export default class DocumentsDocument extends Vue {
  selectedTab = 'virement'

  isLoading = true

  getFrequency (frequency: string) {
    const { t } = useI18n()
    if (frequency !== 'undefined') {
      return t(`package.${frequency}`)
    }
  }

  beforeMount () {
    this.loadDocument()
    this.loadCurrentPackage()
  }

  packageService = inject(PackageServiceKey)
  loadCurrentPackage () {
    this.packageService?.loadCurrentPackage()
        .catch((error) => {
          this.errorHandlerService?.loadError(error.response.data)
        })
  }

  document = {
    title: 'Placeholder name'
  } as Document | Domiciliation | Contract | Certificate | Invoice

  errorHandlerService = inject(ErrorHandlerKey)
  loadDocument () {
    const id = this.$route.params.id.toString()
    getDocument(id)
      .then((response) => {
        this.document = response.data
        this.isLoading = false
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  selectTab (tab: string) {
    this.selectedTab = tab
  }
}
</script>
<style lang="scss" scoped>
.documents-document-payment__title,
.documents-document-payment__status {
  display: flex;
  align-items: center;
}

.documents-document-payment__grid {
  display: grid;
  grid-template-columns: 380px 1fr;
  grid-gap: $xmedium;
  padding-bottom: 10px;

  @include breakpoint(large down) {
    grid-template-columns: 1fr;
    grid-gap: $medium;
  }
}

.documents-document-payment__overview {
  @include white-box;
  padding-top: 24px;
  padding-bottom: 24px;
}

.documents-document-payment__table {
  width: 100%;

  td {
    padding: 5px 0;
  }
}

.documents-document-payment__table-separator {
  border-top: 2px solid $blue-dark-10;
  padding-bottom: 24px;
  width: 100%;
}

.documents-document-payment__payment {
  @include white-box;
}
</style>
